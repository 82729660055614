<template>
	<div class="md-layout">
		<!-- button on the right only if user are admin or superadmin userData.role.toLowerCase() === "admin" || userData.role.toLowerCase() === "super-admin" -->
		<div
			class="md-layout-item md-size-100 admin-switch-container"
			v-if="userData.role.toLowerCase() === 'admin' || userData.role.toLowerCase() === 'super-admin'">
			<div class="switch-wrapper version-c">
				<md-switch v-model="switchAllDevices">
					<span class="switch-text">{{ $t("device.switchAllDevices") }}</span>
				</md-switch>
			</div>
		</div>

		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("device.infoTitle") }}</h4>
					<p v-html="$t('device.infoBody')"></p>
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<h4 class="title">{{ $t("device.list") }}</h4>
					<br />
					<div class="text-right">
						<md-button
							class="md-info md-round"
							@click.native="addDeviceRequest()"
							v-if="hasPermission('device:device_add')">
							<p v-html="$t('device.add')"></p>
							<md-icon>add</md-icon>
						</md-button>
						<md-button class="md-info md-round" @click.native="fetchData()">
							<p v-html="$t('device.refresh')"></p>
							<md-icon>refresh</md-icon>
						</md-button>
						<md-button
							class="md-success md-round"
							@click.native="provisionSelectedDevices()"
							v-if="hasPermission('device:device_template_apply')">
							<p v-html="$t('device.provision')"></p>
							<md-icon>start</md-icon>
						</md-button>
						<!-- Advanced filters -->
						<collapse
							key="advanced-filters"
							:collapse="[$t('fields.advanced_filters')]"
							icon="keyboard_arrow_down"
							color-collapse="warning">
							<template slot="md-collapse-pane-1">
								<div class="md-layout md-gutter" style="justify-content: flex-end">
									<!-- Device status Filter -->
									<div class="md-layout-item md-size-50">
										<md-field>
											<label>{{ $t("general.searchDeviceStatus") }}</label>
											<md-select v-model="filters.status" name="filters.status">
												<md-option :value="0">{{
													$t("fields.device_status.values.0")
												}}</md-option>
												<md-option :value="1">{{
													$t("fields.device_status.values.1")
												}}</md-option>
												<md-option :value="2">{{
													$t("fields.device_status.values.2")
												}}</md-option>
												<md-option :value="3">{{
													$t("fields.device_status.values.3")
												}}</md-option>
											</md-select>
										</md-field>
									</div>
									<!-- Device Group -->
									<div class="md-layout-item md-size-50" v-show="shouldDisplayContent">
										<md-field v-if="deviceGroupsLoaded">
											<label>{{ $t("general.searchDeviceGroup") }}</label>
											<md-select v-model="filters.deviceGroup" name="filters.deviceGroup">
												<md-option :value="''">{{ $t("general.all") }}</md-option>
												<md-option
													v-for="group in deviceGroups"
													:key="group.device_group_id"
													:value="group.device_group_id">
													{{ group.name }}
												</md-option>
											</md-select>
										</md-field>
									</div>
								</div>
								<div class="md-layout md-gutter" style="justify-content: flex-end">
									<!-- Model -->
									<div class="md-layout-item md-size-50">
										<md-field>
											<md-input
												type="search"
												class="mb-3"
												clearable
												style="width: 150px"
												:placeholder="$t('general.searchModel')"
												v-model="filters.queryModel">
											</md-input>
										</md-field>
									</div>
									<!-- Serial -->
									<div class="md-layout-item md-size-50">
										<md-field>
											<md-input
												type="search"
												class="mb-3"
												clearable
												style="width: 150px"
												:placeholder="$t('general.searchSerial')"
												v-model="filters.querySerial">
											</md-input>
										</md-field>
									</div>
								</div>
								<div v-show="!shouldDisplayContent && !loadingError">
									<md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
									<p>{{ $t("fields.loading_data") }}</p>
								</div>
								<div v-show="loadingError">
									<md-card>
										<md-card-header>
											<div class="md-title">Errore di caricamento</div>
										</md-card-header>
										<md-card-content>
											{{ loadingError }}
											<md-button class="md-primary" @click="fetchData">Riprova</md-button>
										</md-card-content>
									</md-card>
								</div>
								<div class="md-layout md-gutter" style="justify-content: flex-end">
									<!-- RouterOS Range -->
									<div class="md-layout-item md-size-50">
										<md-field>
											<label>{{ $t("general.searchRouterOS") }}</label>
											<div class="md-layout md-gutter">
												<div class="md-layout-item">
													<RouterOSVersionSlider @update:range="handleRouterOSRangeUpdate" />
												</div>
											</div>
										</md-field>
									</div>
									<div class="md-layout-item md-size-50">
										<!-- Public IP -->
										<md-field>
											<md-input
												type="search"
												class="mb-3"
												clearable
												style="width: 150px"
												:placeholder="$t('general.searchPublicIp')"
												v-model="filters.queryPublicIp">
											</md-input>
										</md-field>
										<!-- Monitor -->
										<md-field>
											<label>{{ $t("general.searchMonitor") }}</label>
											<md-select v-model="filters.queryMonitor" name="monitor_status">
												<md-option value="">{{ $t("general.all") }}</md-option>
												<md-option value="true">{{ $t("general.yes") }}</md-option>
												<md-option value="false">{{ $t("general.no") }}</md-option>
											</md-select>
										</md-field>
										<!-- backup -->
										<md-field>
											<label>{{ $t("general.searchBackup") }}</label>
											<md-select v-model="filters.queryBackup" name="backup_status">
												<md-option value="">{{ $t("general.all") }}</md-option>
												<md-option value="true">{{ $t("general.yes") }}</md-option>
												<md-option value="false">{{ $t("general.no") }}</md-option>
											</md-select>
										</md-field>
									</div>
									<div class="md-layout-item md-size-15" style="text-align: right">
										<md-button class="md-warning md-round" @click.native="resetFilters()">
											<p v-html="$t('general.searchReset')" style="padding-bottom: 0px"></p>
											<md-icon>filter_alt_off</md-icon>
										</md-button>
									</div>
								</div>
							</template>
						</collapse>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						@md-selected="onSelect"
						class="paginated-table table-striped table-hover centered-header">
						<md-table-toolbar>
							<div class="md-layout md-gutter md-alignment-space-between">
								<!-- Per Page elements-->
								<md-field>
									<label for="pages">{{ $t("general.perPage") }}</label>
									<md-select
										v-model="pagination.perPage"
										name="pages"
										@md-selected="handlePerPageChange">
										<md-option
											v-for="item in pagination.perPageOptions"
											:key="item"
											:label="item === 'All' ? $t('general.all') : item"
											:value="item">
											{{ item === "All" ? $t("general.all") : item }}
										</md-option>
									</md-select>
								</md-field>
								<!-- Search bar -->
								<div class="md-layout md-gutter" style="justify-content: flex-end">
									<div class="md-layout-item md-size-25">
										<md-field>
											<md-input
												type="search"
												class="mb-3"
												clearable
												style="width: 150px"
												:placeholder="$t('general.searchNameID')"
												v-model="filters.queryNameID">
											</md-input>
										</md-field>
									</div>
								</div>
							</div>
						</md-table-toolbar>
						<md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple">
							<!-- id 5% -->
							<md-table-cell
								md-label="ID"
								md-sort-by="device_id"
								style="width: 5%"
								class="centered-content bordered-cell"
								>{{ item.device_id }}</md-table-cell
							>
							<!-- name 15% -->
							<md-table-cell
								:md-label="$t('fields.name.label')"
								md-sort-by="name"
								style="width: 15%"
								class="centered-content bordered-cell"
								><b>{{ item.name }}</b>
								<div style="font-size: x-small" v-if="switchAllDevices">
									<br />
									<b>Customer</b>: {{ item.customer_name }} (ID: {{ item.customer_id }})<br />
									<b>Tenant</b>: {{ item.tenant_name }} (ID: {{ item.tenant_id }})<br />
								</div>
							</md-table-cell>
							<!-- info 15% -->
							<md-table-cell
								md-label="Info"
								md-sort-by="router_os"
								style="width: 15%"
								class="centered-content bordered-cell">
								<div style="font-size: small">
									<b>Model</b>: {{ item.model }}<br />
									<b>RouteOS</b>: {{ item.router_os }}<br />
									<b>Serial</b>: {{ item.serial_number }}<br />
								</div>
							</md-table-cell>
							<!-- public ip 10% -->
							<md-table-cell
								:md-label="$t('fields.public_ip.label')"
								md-sort-by="public_ip"
								style="width: 15%"
								class="centered-content bordered-cell">
								<code class="md-primary md-round" @click="copyToClipboard(item.id, item.public_ip)">
									{{ item.public_ip }}
									<md-icon>content_paste</md-icon>
								</code>
								<span
									v-if="copiedIp && copiedId === item.id && copiedIp === item.public_ip"
									class="clipboard-tooltip"
									>Copiato!</span
								>
							</md-table-cell>
							<!-- device Groups 10% -->
							<md-table-cell
								:md-label="$t('fields.device_groups.label')"
								md-sort-by="device_groups_text"
								style="width: 10%"
								class="centered-content bordered-cell"
								>{{ item.device_groups_text }}</md-table-cell
							>
							<!-- device status 15% -->
							<md-table-cell
								md-label="Device Status"
								style="width: 15%"
								class="centered-content bordered-cell">
								<md-tooltip md-direction="top"><p v-html="$t('device.statusTooltip')"></p></md-tooltip>
								<md-icon v-if="item.on_board && item.is_online" class="md-just-icon icon-online"
									>done_all</md-icon
								>
								<md-icon v-else-if="item.on_board && !item.is_online" class="md-just-icon icon-warning"
									>done</md-icon
								>
								<md-icon v-else class="md-just-icon icon-danger">close</md-icon>
								<br />
								<span style="font-size: small">
									<b>Last seen</b><br />
									{{ formatToLocal(item.last_seen) }}
								</span>
							</md-table-cell>
							<!-- action 25% -->
							<md-table-cell
								:md-label="$t('fields.actions.label')"
								style="width: 25%"
								class="centered-content bordered-cell">
								<actions class="hide-on-large" style="margin-right: 14px">
									<!-- SDWAN -->
									<md-button
										class="md-simple"
										:class="[item.sdwan_active ? 'md-primary' : 'md-info']"
										@click.native="handleConfigureSDWAN(item)"
										v-if="hasPermission('device:device_sdwan')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.sdwanTooltip')"></p>
										</md-tooltip>
										<div class="icon-with-label">
											<md-icon>lan</md-icon>
											<div style="font-size: xx-small">SDWAN</div>
										</div>
									</md-button>

									<!-- Console -->
									<md-button
										class="md-simple md-primary"
										@click.native="openConsole(item)"
										v-if="hasPermission('device:device_console')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.consoleTooltip')"></p>
										</md-tooltip>
										<div class="icon-with-label">
											<md-icon>terminal</md-icon>
											<div style="font-size: xx-small">Console</div>
										</div>
									</md-button>

									<!-- Winbox -->
									<md-button
										class="md-simple md-primary"
										@click.native="openWinbox(item)"
										v-if="hasPermission('device:device_webwinbox')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.winboxTooltip')"></p>
										</md-tooltip>
										<div class="icon-with-label">
											<md-icon>dvr</md-icon>
											<div style="font-size: xx-small">Winbox</div>
										</div>
									</md-button>

									<!-- Monitoring -->
									<md-button
										class="md-simple"
										:class="[item.is_monitored ? 'md-success' : 'md-danger']"
										@click.native="handleMonitoringRequest(item)"
										v-if="hasPermission('device:device_monitor')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.monitoringTooltip')"></p
										></md-tooltip>
										<md-tooltip md-direction="top"
											><p v-html="$t('device.monitoringTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>monitor_heart</md-icon>
											<div style="font-size: xx-small">Monitor</div>
										</div>
									</md-button>

									<!-- Backup -->
									<md-button
										class="md-simple"
										:class="[item.on_backup ? 'md-success' : 'md-danger']"
										@click.native="handleBackupRequest(item)"
										v-if="hasPermission('device:device_backup')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.backupTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>{{ item.on_backup ? "cloud_done" : "cloud_off" }}</md-icon>
											<div style="font-size: xx-small">Backup</div>
										</div>
									</md-button>

									<!-- Troubleshooting -->
									<md-button
										class="md-simple md-warning"
										@click.native="redirectTroubleshooting(item)"
										v-if="hasPermission('device:device_troubleshoot')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.troubleshootingTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>settings_input_component</md-icon>
											<div style="font-size: xx-small">Tools</div>
										</div>
									</md-button>

									<!-- Move -->
									<md-button
										class="md-simple md-warning"
										@click.native="handleMoveDevice(item)"
										v-if="hasPermission('device:device_move') && switchAllDevices">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.moveTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>cached</md-icon>
											<div style="font-size: xx-small">Move</div>
										</div>
									</md-button>

									<md-button
										class="md-simple md-warning"
										@click.native="editDevice(item)"
										v-if="hasPermission('device:device_edit')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.editTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>edit</md-icon>
											<div style="font-size: xx-small">Edit</div>
										</div>
									</md-button>

									<md-button
										class="md-simple md-info"
										@click.native="handleGetCommands(item)"
										v-if="hasPermission('device:device_oboard')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.oboardingTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>keyboard_command_key</md-icon>
											<div style="font-size: xx-small">Onboard</div>
										</div>
									</md-button>
									<md-button
										class="md-simple md-warning"
										@click.native="handleAdminPasswordReset(item)"
										v-if="hasPermission('device:device_password_reset')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.resetTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>lock_reset</md-icon>
											<div style="font-size: xx-small">Pwd</div>
										</div>
									</md-button>
									<md-button
										class="md-simple md-success"
										@click.native="updateInfoDevice(item)"
										v-if="hasPermission('device:device_update_info')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.updateInfoDeviceTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>update</md-icon>
											<div style="font-size: xx-small">Update</div>
										</div>
									</md-button>
									<md-button
										class="md-simple md-danger"
										@click.native="handleDelete(item)"
										v-if="hasPermission('device:device_delete')">
										<md-tooltip md-direction="top"
											><p v-html="$t('device.deleteTooltip')"></p
										></md-tooltip>
										<div class="icon-with-label">
											<md-icon>delete</md-icon>
											<div style="font-size: xx-small">Delete</div>
										</div>
									</md-button>
								</actions>
								<!-- Desktop version -->
								<div>
									<div>
										<!-- SDWAN -->
										<md-button
											class="md-just-icon md-simple md-medium-hide spacing-both"
											:class="[item.sdwan_active ? 'md-primary' : 'md-info']"
											@click.native="handleConfigureSDWAN(item)"
											v-if="hasPermission('device:device_sdwan')">
											<md-tooltip md-direction="top">
												<p v-html="$t('device.sdwanTooltip')"></p>
											</md-tooltip>
											<div class="icon-with-label">
												<md-icon>lan</md-icon>
												<div style="font-size: xx-small">SDWAN</div>
											</div>
										</md-button>

										<!-- Console -->
										<md-button
											class="md-just-icon md-primary md-simple md-medium-hide spacing-both"
											@click.native="openConsole(item)"
											v-if="hasPermission('device:device_console')">
											<md-tooltip md-direction="top">
												<p v-html="$t('device.consoleTooltip')"></p>
											</md-tooltip>
											<div class="icon-with-label">
												<md-icon>terminal</md-icon>
												<div style="font-size: xx-small">Console</div>
											</div>
										</md-button>
										<!-- Winbox -->
										<md-button
											class="md-just-icon md-primary md-simple md-medium-hide spacing-both"
											@click.native="openWinbox(item)"
											v-if="hasPermission('device:device_webwinbox')">
											<md-tooltip md-direction="top">
												<p v-html="$t('device.winboxTooltip')"></p>
											</md-tooltip>
											<div class="icon-with-label">
												<md-icon>dvr</md-icon>
												<div style="font-size: xx-small">Winbox</div>
											</div>
										</md-button>
										<!-- Monitoring -->
										<md-button
											class="md-just-icon md-simple md-medium-hide spacing-both"
											:class="[item.is_monitored ? 'md-success' : 'md-danger']"
											@click.native="handleMonitoringRequest(item)"
											v-if="hasPermission('device:device_monitor')">
											<md-tooltip md-direction="top"
												><p v-html="$t('device.monitoringTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>monitor_heart</md-icon>
												<div style="font-size: xx-small">Monitor</div>
											</div>
										</md-button>
										<!-- Backup -->
										<md-button
											class="md-just-icon md-simple md-medium-hide spacing-both"
											:class="[item.on_backup ? 'md-success' : 'md-danger']"
											@click.native="handleBackupRequest(item)"
											v-if="hasPermission('device:device_backup')">
											<md-tooltip md-direction="top"
												><p v-html="$t('device.backupTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>{{ item.on_backup ? "cloud_done" : "cloud_off" }}</md-icon>
												<div style="font-size: xx-small">Backup</div>
											</div>
										</md-button>
										<!-- Commands -->
										<md-button
											class="md-just-icon md-simple md-warning md-medium-hide spacing-both"
											@click.native="redirectTroubleshooting(item)"
											v-if="hasPermission('device:device_troubleshoot')">
											<md-tooltip md-direction="top"
												><p v-html="$t('device.troubleshootingTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>settings_input_component</md-icon>
												<div style="font-size: xx-small">Tools</div>
											</div>
										</md-button>
									</div>

									<div>
										<md-button
											class="md-just-icon md-simple md-warning md-medium-hide spacing-both"
											@click.native="handleMoveDevice(item)"
											v-if="hasPermission('device:device_move') && switchAllDevices">
											<md-tooltip md-direction="top"
												><p v-html="$t('device.moveTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>cached</md-icon>
												<div style="font-size: xx-small">Move</div>
											</div>
										</md-button>
										<md-button
											class="md-just-icon md-simple md-warning md-medium-hide spacing-both"
											@click.native="editDevice(item)"
											v-if="hasPermission('device:device_edit')">
											<md-tooltip md-direction="top"
												><p v-html="$t('device.editTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>edit</md-icon>
												<div style="font-size: xx-small">Edit</div>
											</div>
										</md-button>
										<md-button
											class="md-just-icon md-simple md-info md-medium-hide spacing-both"
											@click.native="handleGetCommands(item)"
											v-if="hasPermission('device:device_onboard')">
											<md-tooltip md-direction="top"
												><p v-html="$t('device.oboardingTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>keyboard_command_key</md-icon>
												<div style="font-size: xx-small">Onboard</div>
											</div>
										</md-button>

										<md-button
											class="md-just-icon md-simple md-warning md-medium-hide spacing-both"
											@click.native="handleAdminPasswordReset(item)"
											v-if="hasPermission('device:device_password_reset')">
											<md-tooltip md-direction="top"
												><p v-html="$t('device.resetTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>lock_reset</md-icon>
												<div style="font-size: xx-small">Pwd</div>
											</div>
										</md-button>
										<md-button
											class="md-just-icon md-simple md-success md-medium-hide spacing-both"
											@click.native="updateInfoDevice(item)"
											v-if="hasPermission('device:device_update_info')">
											<md-tooltip md-direction="top"
												><p v-html="$t('device.updateInfoDeviceTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>update</md-icon>
												<div style="font-size: xx-small">Update</div>
											</div>
										</md-button>

										<md-button
											class="md-just-icon md-simple md-danger md-medium-hide spacing-both"
											@click.native="handleDelete(item)"
											v-if="hasPermission('device:device_delete')">
											<md-tooltip md-direction="top"
												><p v-html="$t('device.deleteTooltip')"></p
											></md-tooltip>
											<div class="icon-with-label">
												<md-icon>delete</md-icon>
												<div style="font-size: xx-small">Delete</div>
											</div>
										</md-button>
									</div>
								</div>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ $t("general.pagination_info", { from: from + 1, to, total }) }}
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total">
					</pagination>
				</md-card-actions>
				<div class="table table-stats">
					<div class="text-right">
						<md-button class="md-success md-round" @click.native="provisionSelectedDevices()">
							<p v-html="$t('device.provision')"></p>
							<md-icon>start</md-icon>
						</md-button>
					</div>
				</div>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from "@/components";
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import Actions from "../../../components/Actions.vue";
import { mapGetters } from "vuex";
import RouterOSVersionSlider from "./RouterOSVersionSlider";
import Collapse from "../../../components/Collapse.vue";

export default {
	name: "PaginatedTable",
	components: {
		Pagination,
		Actions,
		RouterOSVersionSlider,
		Collapse,
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),

		queriedData() {
			if (!Array.isArray(this.searchedData) || this.searchedData.length === 0) {
				return [];
			}

			if (this.pagination.perPage === "All") {
				return [...this.searchedData];
			}

			return this.searchedData.slice(this.from, this.to);
		},
		to() {
			if (this.pagination.perPage === "All") {
				return this.total;
			}
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			if (this.pagination.perPage === "All") {
				return 0;
			}
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
		},
		isDataReady() {
			return this.deviceGroupsLoaded && this.devicesLoaded;
		},
	},
	data() {
		return {
			currentSort: "name",
			currentSortOrder: "asc",
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50, 100, 500, "All"],
				total: 0,
			},
			filters: {
				status: null,
				queryNameID: "",
				queryModel: "",
				querySerial: "",
				queryPublicIp: "",
				queryMonitor: "",
				queryBackup: "",
				deviceGroup: "",
				routerOSMin: "",
				routerOSMax: "",
			},
			footerTable: ["Id", "Name", "Serial", "Device Groups", "On Board"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			deviceGroups: [],
			selectedDevices: [],
			timer: null,
			copiedId: null,
			copiedIp: "",
			deviceGroupsLoaded: false,
			devicesLoaded: false,
			loadingError: null,
			shouldDisplayContent: false,
			switchAllDevices: localStorage.getItem("switchAllDevices") === "true", // Recupera lo stato salvato
			userData: TokenService.getUser(),
			customers: [],
		};
	},
	methods: {
		formatToLocal(date) {
			const localDate = new Date(date);
			return localDate.toLocaleString(); // Puoi personalizzare il formato, vedi più avanti
		},
		copyToClipboard(id, ip) {
			if (!navigator.clipboard) {
				this.fallbackCopyTextToClipboard(ip);
				return;
			}
			navigator.clipboard.writeText(ip).then(
				() => {
					this.copiedId = id;
					this.copiedIp = ip;
					setTimeout(() => {
						this.copiedId = null;
						this.copiedIp = "";
					}, 2000);
				},
				(err) => {
					// console.error('Could not copy text to clipboard', err);
				}
			);
		},
		fallbackCopyTextToClipboard(text) {
			var textArea = document.createElement("textarea");
			textArea.value = text;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			try {
				var successful = document.execCommand("copy");
				var msg = successful ? "successful" : "unsuccessful";
				// console.log('Fallback copying text command was ' + msg);
			} catch (err) {
				// console.error('Could not copy text to clipboard', err);
			}
			document.body.removeChild(textArea);
		},
		searchByStatus(results) {
			if (!this.filters.status) {
				return results;
			}

			return results.filter((item) => item.status_computed === this.filters.status);
		},
		searchByQueryNameID(results) {
			if (this.filters.queryNameID === "") {
				return results;
			}
			this.fuseSearch = new Fuse(results, {
				keys: ["name", "device_id"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});
			return this.fuseSearch.search(this.filters.queryNameID).map((el) => el.item);
		},

		searchByQueryModel(results) {
			if (this.filters.queryModel === "") {
				return results;
			}
			this.fuseSearch = new Fuse(results, {
				keys: ["model"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});
			return this.fuseSearch.search(this.filters.queryModel).map((el) => el.item);
		},
		searchByQuerySerial(results) {
			if (this.filters.querySerial === "") {
				return results;
			}
			this.fuseSearch = new Fuse(results, {
				keys: ["serial_number"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});
			return this.fuseSearch.search(this.filters.querySerial).map((el) => el.item);
		},
		searchByQueryPublicIp(results) {
			if (this.filters.queryPublicIp === "") {
				return results;
			}
			this.fuseSearch = new Fuse(results, {
				keys: ["public_ip"],
				threshold: 0.3,
				ignoreLocation: true,
				shouldSort: true,
			});
			return this.fuseSearch.search(this.filters.queryPublicIp).map((el) => el.item);
		},
		searchByBackupStatus(results) {
			if (this.filters.queryBackup === "") {
				return results;
			}
			const backupStatus = this.filters.queryBackup === "true";
			return results.filter((item) => item.on_backup === backupStatus);
		},
		searchByMonitorStatus(results) {
			if (this.filters.queryMonitor === "") {
				return results;
			}
			const monitorStatus = this.filters.queryMonitor === "true";
			return results.filter((item) => item.is_monitored === monitorStatus);
		},
		searchByDeviceGroup(results) {
			if (!this.filters.deviceGroup || this.filters.deviceGroup === "") {
				return results;
			}
			return results.filter(
				(item) => Array.isArray(item.device_groups) && item.device_groups.includes(this.filters.deviceGroup)
			);
		},
		handleRouterOSRangeUpdate(newRange) {
			this.filters.routerOSMin = newRange[0];
			this.filters.routerOSMax = newRange[1];
		},
		compareVersions(v1, v2) {
			const parts1 = v1.split(".").map(Number);
			const parts2 = v2.split(".").map(Number);

			for (let i = 0; i < 3; i++) {
				if (parts1[i] > parts2[i]) return 1;
				if (parts1[i] < parts2[i]) return -1;
			}

			return 0;
		},
		searchByRouterOSRange(results) {
			if (!this.filters.routerOSMin && !this.filters.routerOSMax) {
				return results;
			}

			return results.filter((item) => {
				const version = item.router_os.replace(/\s*\(stable\)/, "").trim();
				const min = this.filters.routerOSMin || "0.0.0";
				const max = this.filters.routerOSMax || "999.999.999";

				return this.compareVersions(version, min) >= 0 && this.compareVersions(version, max) <= 0;
			});
		},
		handlePerPageChange(value) {
			if (value === "All") {
				this.pagination.perPage = this.pagination.total;
			} else {
				this.pagination.perPage = value;
			}
			this.pagination.currentPage = 1;
			this.fetchData();
		},

		resetFilters() {
			this.filters = {
				status: null,
				queryNameID: "",
				queryModel: "",
				querySerial: "",
				queryPublicIp: "",
				queryMonitor: "",
				queryBackup: "",
				deviceGroup: "",
				routerOSMin: "",
				routerOSMax: "",
			};

			// Resetta anche la paginazione
			this.pagination.currentPage = 1;

			// Applica i filtri resettati
			this.applyFilters();

			// Opzionale: puoi anche voler richiamare fetchData per assicurarti di avere i dati più recenti
			this.fetchData();
		},

		async fetchData() {
			let userData = TokenService.getUser();

			try {
				this.loadingError = null;
				this.deviceGroupsLoaded = false;
				this.devicesLoaded = false;

				// Fetch device groups
				const deviceGroupsResponse = await evoapi.get("/customers/" + userData.customerId + "/device-groups");
				this.deviceGroups = deviceGroupsResponse.data;
				this.deviceGroupsLoaded = true;

				// Fetch devices
				const endpoint = this.switchAllDevices
					? "/customers/" + userData.customerId + "/allDevices"
					: "/customers/" + userData.customerId + "/devices";

				const devicesResponse = await evoapi.get(endpoint);

				// Ensure we're working with an array
				if (Array.isArray(devicesResponse.data)) {
					this.tableData = devicesResponse.data;
				} else {
					this.tableData = []; // Set to empty array if response is not an array
					// console.error('Expected array response from API');
					return;
				}

				// Process the data
				let now = new Date();
				this.tableData = this.tableData.map((item) => {
					const isOnline = item.last_seen ? Math.round((now - new Date(item.last_seen)) / 60000) < 6 : false;
					const statusComputed = item.on_board ? (isOnline ? 1 : 2) : 3;
					const deviceGroupsText = this.processDeviceGroups(item.device_groups);
					// Create a new object for each item
					return {
						...item,
						is_online: isOnline,
						status_computed: statusComputed,
						device_groups_text: deviceGroupsText,
					};
				});

				this.searchedData = [...this.tableData];
				this.pagination.total = this.tableData.length;
				this.devicesLoaded = true;
			} catch (error) {
				this.loadingError = "Si è verificato un errore durante il caricamento dei dati.";
				this.deviceGroupsLoaded = false;
				this.devicesLoaded = false;
				// console.error('Error fetching data:', error);
			}
		},
		// Helper method to process device groups
		processDeviceGroups(groups) {
			if (!Array.isArray(groups)) return "";

			return groups
				.map((groupId) => {
					const deviceGroup = this.deviceGroups.find((group) => group.device_group_id == groupId);
					return deviceGroup ? deviceGroup.name : groupId;
				})
				.join(",");
		},

		applyFilters() {
			if (!this.isDataReady) return;

			let results = this.tableData;

			if (this.filters.queryNameID) {
				results = this.searchByQueryNameID(results);
			}
			if (this.filters.queryModel) {
				results = this.searchByQueryModel(results);
			}
			if (this.filters.querySerial) {
				results = this.searchByQuerySerial(results);
			}
			if (this.filters.queryPublicIp) {
				results = this.searchByQueryPublicIp(results);
			}
			if (this.filters.status !== null) {
				results = this.searchByStatus(results);
			}
			if (this.filters.deviceGroup) {
				results = this.searchByDeviceGroup(results);
			}
			if (this.filters.routerOSMin || this.filters.routerOSMax) {
				results = this.searchByRouterOSRange(results);
			}
			if (this.filters.queryBackup) {
				results = this.searchByBackupStatus(results);
			}
			if (this.filters.queryMonitor) {
				results = this.searchByMonitorStatus(results);
			}

			this.searchedData = results;
		},
		onSelect(items) {
			this.selectedDevices = [];
			items.forEach((item) => {
				this.selectedDevices.push(item.device_id);
			});
		},
		editDevice(device) {
			this.$router.push({
				name: "Edit device",
				params: { device: device, device_id: device.device_id },
			});
		},
		async handleMoveDevice(item) {
			// Prepara le opzioni dei clienti escludendo il cliente corrente
			const customerOptions = this.customers
				.filter((c) => c.customer_id != item.customer_id) // Esclude il cliente attuale
				.map((c) => ({
					id: c.customer_id,
					text: `${c.name} (ID: ${c.customer_id}) - ${c.tenant_name}`,
				}));

			// Mostra la modale di selezione
			const { value: selectedCustomerId } = await Swal.fire({
				title: this.$t("dialogs.move_device.title"),
				html: `
					<select id="customerSelect" class="swal2-select" style="width: 100%; padding: 8px; margin: 20px 0;">
						<option value="" disabled selected>${this.$t("dialogs.move_device.select_customer")}</option>
						${customerOptions.map((c) => `<option value="${c.id}">${c.text}</option>`).join("")}
					</select>
				`,
				width: "600px", // Aumenta la larghezza della modale
				padding: "2em", // Aumenta il padding interno
				showCancelButton: true,
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonText: this.$t("dialogs.move_device.ok"),
				preConfirm: () => {
					const select = document.getElementById("customerSelect");
					const selectedId = select.value;
					if (!selectedId) {
						Swal.showValidationMessage(this.$t("dialogs.move_device.validation_message"));
						return false;
					}
					return selectedId;
				},
			});

			if (selectedCustomerId) {
				// Mostra la modale di conferma
				const confirmResult = await Swal.fire({
					title: this.$t("dialogs.move_device.confirm_title"),
					html: this.$t("dialogs.move_device.confirm_content", {
						deviceName: item.name,
						customerName: customerOptions.find((c) => c.id === parseInt(selectedCustomerId)).text,
					}),
					icon: "warning",
					showCancelButton: true,
					cancelButtonText: this.$t("general.cancel"),
					confirmButtonText: this.$t("dialogs.move_device.confirm"),
					customClass: {
						confirmButton: "md-button md-success",
						cancelButton: "md-button md-danger btn-fill",
					},
					buttonsStyling: false,
				});

				if (confirmResult.isConfirmed) {
					try {
						// Mostra loading
						Swal.fire({
							title: this.$t("dialogs.move_device.moving_title"),
							html: this.$t("dialogs.move_device.moving_content"),
							allowEscapeKey: false,
							allowOutsideClick: false,
							didOpen: () => {
								Swal.showLoading();
							},
						});

						// Chiama l'API per spostare il dispositivo
						//let userData = TokenService.getUser();
						await evoapi.post(`/customers/${item.customer_id}/moveDevice/${item.device_id}`, {
							new_customer_id: parseInt(selectedCustomerId),
						});

						// Mostra successo e aggiorna la tabella
						await Swal.fire({
							title: this.$t("dialogs.move_device.success_title"),
							html: this.$t("dialogs.move_device.success_content"),
							icon: "success",
							confirmButtonText: this.$t("general.ok"),
							customClass: {
								confirmButton: "md-button md-success",
							},
							buttonsStyling: false,
						});

						// Aggiorna la lista dei dispositivi
						this.fetchData();
					} catch (error) {
						// Gestione errori
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							html: error.response?.data?.error
								? `${this.$t("responses.error.content")}<br><br><code>${
										error.response.data.error
								  }</code>`
								: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					}
				}
			}
		},
		addDeviceRequest() {
			this.$router.push({ name: "Add Device" });
		},
		updateInfoDevice(item) {
			let userData = TokenService.getUser();
			evoapi.get("/customers/" + item.customer_id + "/devices/" + item.device_id + "/updateInfo");
			// .then((response) => {
			Swal.fire({
				title: this.$t("dialogs.update_info.title"),
				html: this.$t("dialogs.update_info.content"),
				confirmButtonText: this.$t("dialogs.update_info.ok"),
				showCloseButton: false,
				icon: "success",
				allowOutsideClick: false,
				backdrop: `rgba(0,150,0,0.4)`,
			}).then(() => {
				this.fetchData();
			});
			// });
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return a[sortBy].toString().localeCompare(b[sortBy]);
				}
				return b[sortBy].toString().localeCompare(a[sortBy]);
			});
		},
		async handleAdminPasswordReset(item) {
			let userData = TokenService.getUser();
			let device_id = item.device_id;

			// Mostra notifica di attesa
			Swal.fire({
				title: this.$t("dialogs.loading.title"),
				html: this.$t("dialogs.loading.content"),
				timerProgressBar: true,
				allowOutsideClick: false,
				backdrop: `rgba(130,0,160,0.4)`,
				didOpen: () => {
					Swal.showLoading();
					// Chiamata a evoapi
					evoapi
						.get("/customers/" + item.customer_id + "/health/check-device-availability/" + device_id)
						.then((response) => {
							Swal.close(); // Chiudi la notifica di attesa
							if (response.status === 200) {
								Swal.fire({
									title: this.$t("dialogs.reset_password.title"),
									text: this.$t("dialogs.reset_password.content", { subject: item.name }),
									icon: "warning",
									showCancelButton: true,
									customClass: {
										confirmButton: "md-button md-success",
										cancelButton: "md-button md-danger btn-fill",
									},
									cancelButtonText: this.$t("general.cancel"),
									confirmButtonText: this.$t("dialogs.reset_password.ok"),
									buttonsStyling: false,
								}).then((result) => {
									if (result.isConfirmed) {
										Swal.fire({
											title: this.$t("dialogs.reset_password_random.title"),
											html: this.$t("dialogs.reset_password_random.content"),
											allowEscapeKey: false,
											allowOutsideClick: false,
											didOpen: () => {
												Swal.showLoading();
											},
										});
										let userData = TokenService.getUser();
										evoapi
											.post(
												"/customers/" +
													item.customer_id +
													"/devices/" +
													item.device_id +
													"/reset-admin-password"
											)
											.then((response) => {
												let password = response.data.password;
												Swal.fire({
													title: this.$t("dialogs.reset_password_new.title"),
													html: this.$t("dialogs.reset_password_new.content", { password }),
													// html: "<code style=\"font-size: xx-large\"><b>"+response.data.password+"</b></code>",
													footer: this.$t("dialogs.reset_password_new.footer"),
													confirmButtonText: this.$t("dialogs.reset_password_new.ok"),
													showCloseButton: false,
													// type: "success",
													allowOutsideClick: false,
													backdrop: `rgba(0,0,123,0.4)`,
												}).then((result) => {
													if (result.isConfirmed) {
														if (window.clipboardData && window.clipboardData.setData) {
															// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
															return window.clipboardData.setData("Text", password);
														} else if (
															document.queryCommandSupported &&
															document.queryCommandSupported("copy")
														) {
															var textarea = document.createElement("textarea");
															textarea.textContent = password;
															textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
															document.body.appendChild(textarea);
															textarea.select();
															try {
																return document.execCommand("copy"); // Security exception may be thrown by some browsers.
															} catch (ex) {
															} finally {
																document.body.removeChild(textarea);
															}
														}
													}
												});
											})
											.catch(function (error) {
												if (error.response) {
													Swal.fire({
														icon: "error",
														title: this.$t("responses.error.title"),
														text: this.$t("responses.error.content"),
														footer: this.$t("responses.error.footer"),
													});
												}
											});
									}
								});
							} else {
								throw new Error("Device not available");
							}
						})
						.catch((error) => {
							Swal.fire({
								title: this.$t("dialogs.check_device_availability.title"),
								html: this.$t("dialogs.check_device_availability.content"),
								confirmButtonText: this.$t("dialogs.check_device_availability.ok"),
								showCloseButton: false,
								icon: "error",
								allowOutsideClick: false,
								backdrop: `rgba(150,0,0,0.4)`,
							}).then(() => {
								this.fetchData();
							});
						});
				},
			});
		},
		async handleConfigureSDWAN(item) {
			let userData = TokenService.getUser();
			let device_id = item.device_id;
			const token = TokenService.getLocalAccessToken();
			const title = item.name;
			let url =
				"/console/?hostname=127.0.0.1&username=optiwize&device_id=" +
				device_id +
				"&token=" +
				token +
				"&title=" +
				title +
				"#fontsize=13#bgcolor=#3B3B3B";

			// Mostra notifica di attesa
			Swal.fire({
				title: this.$t("dialogs.loading.title"),
				html: this.$t("dialogs.loading.content"),
				timerProgressBar: true,
				allowOutsideClick: false,
				backdrop: `rgba(130,0,160,0.4)`,
				didOpen: () => {
					Swal.showLoading();
					// Chiamata a evoapi
					evoapi
						.get("/customers/" + item.customer_id + "/health/check-device-availability/" + device_id)
						.then((response) => {
							Swal.close(); // Chiudi la notifica di attesa
							if (response.status === 200) {
								// Se il dispositivo è disponibile
								if (!item.sdwan_active) {
									Swal.fire({
										title: this.$t("dialogs.configure_sdwan.title"),
										html: this.$t("dialogs.configure_sdwan.content", {
											subject: item.name,
										}),
										icon: "warning",
										showCancelButton: true,
										customClass: {
											confirmButton: "md-button md-success",
											cancelButton: "md-button md-danger btn-fill",
										},
										cancelButtonText: this.$t("general.cancel"),
										confirmButtonText: this.$t("general.ok"),
										buttonsStyling: false,
									}).then((result) => {
										if (result.isConfirmed) {
											this.$router.push({
												name: "Start SDWAN",
												params: { device_id: item.device_id, device_name: item.name },
											});
										}
									});
								} else {
									Swal.fire({
										title: this.$t("dialogs.delete_update_sdwan.title"),
										showDenyButton: false,
										showCancelButton: true,
										confirmButtonText: this.$t("dialogs.delete_update_sdwan.edit"),
										denyButtonText: this.$t("dialogs.delete_update_sdwan.delete"),
										cancelButtonText: this.$t("general.cancel"),
										confirmButtonClass: "button-warning",
									}).then((result) => {
										if (result.isConfirmed) {
											let userData = TokenService.getUser();
											evoapi
												.get(
													"/customers/" +
														item.customer_id +
														"/sdwan/config/" +
														item.sdwan_contract_id
												)
												.then((response) => {
													if (response.data.device_id) {
														this.$router.push({
															name: "Edit Provisioned SDWAN",
															params: {
																pageTitle: "Edit SDWAN",
																sdwanConnection: response.data,
																device_id: response.data.device_id,
															},
														});
													} else {
														this.$router.push({
															name: "Edit SDWAN",
															params: {
																pageTitle: "Edit SDWAN",
																sdwanConnection: response.data,
															},
														});
													}
												})
												.catch(function (error) {
													if (error.response) {
														Swal.fire({
															icon: "error",
															title: this.$t("responses.error.title"),
															text: this.$t("responses.error.content"),
															footer: this.$t("responses.error.footer"),
														});
													} else {
														// console.log(error);
													}
												});
										} else if (result.isDenied) {
											Swal.fire({
												title: this.$t("dialogs.delete_sdwan_confirmation.title"),
												text: this.$t("dialogs.delete_sdwan_confirmation.content", {
													subject: item.name,
												}),
												icon: "warning",
												showCancelButton: true,
												customClass: {
													confirmButton: "md-button md-success",
													cancelButton: "md-button md-danger btn-fill",
												},
												cancelButtonText: this.$t("dialogs.delete_sdwan_confirmation.cancel"),
												confirmButtonText: this.$t("dialogs.delete_sdwan_confirmation.ok"),
												buttonsStyling: false,
											}).then((result) => {
												if (result.value) {
													let userData = TokenService.getUser();
													Swal.fire({
														title: this.$t(
															"dialogs.delete_sdwan_confirmation_progress.title"
														),
														html: this.$t(
															"dialogs.delete_sdwan_confirmation_progress.content"
														),
														allowEscapeKey: false,
														allowOutsideClick: false,
														didOpen: () => {
															Swal.showLoading();
														},
													});
													evoapi
														.delete(
															"/customers/" +
																item.customer_id +
																"/sdwan/config/" +
																item.sdwan_contract_id
														)
														.then((response) => {
															this.deleteRow(item);
															item.sdwan_active = false;
															Swal.fire({
																title: this.$t(
																	"dialogs.delete_sdwan_confirmation_finish.title"
																),
																text: this.$t(
																	"dialogs.delete_sdwan_confirmation_finish.content",
																	{
																		subject: item.name,
																	}
																),
																icon: "success",
																customClass: {
																	confirmButton: "md-button md-success",
																},
																buttonsStyling: false,
															});
														})
														.catch(function (error) {
															if (error.response) {
																Swal.fire({
																	icon: "error",
																	title: this.$t("responses.error.title"),
																	text: this.$t("responses.error.content"),
																	footer: this.$t("responses.error.footer"),
																});
															}
														});
												}
											});
										}
									});
								}
							} else {
								throw new Error("Device not available");
							}
						})
						.catch((error) => {
							Swal.fire({
								title: this.$t("dialogs.check_device_availability.title"),
								html: this.$t("dialogs.check_device_availability.content"),
								confirmButtonText: this.$t("dialogs.check_device_availability.ok"),
								showCloseButton: false,
								icon: "error",
								allowOutsideClick: false,
								backdrop: `rgba(150,0,0,0.4)`,
							});
						});
				},
			});
		},
		async openConsole(item) {
			let userData = TokenService.getUser();
			let device_id = item.device_id;
			const token = TokenService.getLocalAccessToken();
			const title = item.name;
			let url =
				"/console/?hostname=127.0.0.1&username=optiwize&device_id=" +
				device_id +
				"&token=" +
				token +
				"&title=" +
				title +
				"#fontsize=13#bgcolor=#3B3B3B";
			// Mostra notifica di attesa
			Swal.fire({
				title: this.$t("dialogs.loading.title"),
				html: this.$t("dialogs.loading.content"),
				timerProgressBar: true,
				allowOutsideClick: false,
				backdrop: `rgba(130,0,160,0.4)`,
				didOpen: () => {
					Swal.showLoading();
					// Chiamata a evoapi
					evoapi
						.get("/customers/" + item.customer_id + "/health/check-device-availability/" + device_id)
						.then((response) => {
							Swal.close(); // Chiudi la notifica di attesa
							if (response.status === 200) {
								window.open(url, "_blank");
							} else {
								throw new Error("Device not available");
							}
						})
						.catch((error) => {
							Swal.fire({
								title: this.$t("dialogs.check_device_availability.title"),
								html: this.$t("dialogs.check_device_availability.content"),
								confirmButtonText: this.$t("dialogs.check_device_availability.ok"),
								showCloseButton: false,
								icon: "error",
								allowOutsideClick: false,
								backdrop: `rgba(150,0,0,0.4)`,
							}).then(() => {
								this.fetchData();
							});
						});
				},
			});
		},
		async openWinbox(item) {
			let userData = TokenService.getUser();
			let device_id = item.device_id;
			// console.log("openWinbox", device_id);
			// ora devo aprire in una nuova tab la console chiamando "/winbox/vnc.html?vnc.html?width=<larghezza>&height=<altezza>&hostname=127.0.0.1&username=optiwize&device_id=<device_id>&token=<token>&title=<device_name>
			// senza usare evoapi, solo aprendo una nuova tab
			// recupero larghezza schermo
			let width = window.innerWidth;
			let height = window.innerHeight;
			const token = TokenService.getLocalAccessToken();
			const title = item.name;
			// Genera un TIMESTAMP univoco
			const timestamp = Date.now();
			let url =
				"/winbox/vnc.html?width=" +
				width +
				"&height=" +
				height +
				"&hostname=127.0.0.1&username=optiwize&device_id=" +
				device_id +
				"&token=" +
				token +
				"&title=" +
				title +
				"&timestamp=" +
				timestamp;
			// Mostra notifica di attesa
			Swal.fire({
				title: this.$t("dialogs.loading.title"),
				html: this.$t("dialogs.loading.content"),
				timerProgressBar: true,
				allowOutsideClick: false,
				backdrop: `rgba(130,0,160,0.4)`,
				didOpen: () => {
					Swal.showLoading();
					// Chiamata a evoapi
					evoapi
						.get("/customers/" + item.customer_id + "/health/check-device-availability/" + device_id)
						.then((response) => {
							Swal.close(); // Chiudi la notifica di attesa
							if (response.status === 200) {
								window.open(url, "_blank");
							} else {
								throw new Error("Device not available");
							}
						})
						.catch((error) => {
							Swal.fire({
								title: this.$t("dialogs.check_device_availability.title"),
								html: this.$t("dialogs.check_device_availability.content"),
								confirmButtonText: this.$t("dialogs.check_device_availability.ok"),
								showCloseButton: false,
								icon: "error",
								allowOutsideClick: false,
								backdrop: `rgba(150,0,0,0.4)`,
							}).then(() => {
								this.fetchData();
							});
						});
				},
			});
		},
		async redirectTroubleshooting(item) {
			let userData = TokenService.getUser();
			let device_id = item.device_id;

			// Mostra notifica di attesa
			Swal.fire({
				title: this.$t("dialogs.loading.title"),
				html: this.$t("dialogs.loading.content"),
				timerProgressBar: true,
				allowOutsideClick: false,
				backdrop: `rgba(130,0,160,0.4)`,
				didOpen: () => {
					Swal.showLoading();
					// Chiamata a evoapi
					evoapi
						.get("/customers/" + item.customer_id + "/health/check-device-availability/" + device_id)
						.then((response) => {
							Swal.close(); // Chiudi la notifica di attesa
							if (response.status === 200) {
								this.$router.push({
									name: "Device troubleshooting",
									params: { device_id: item.device_id },
								});
							} else {
								throw new Error("Device not available");
							}
						})
						.catch((error) => {
							Swal.fire({
								title: this.$t("dialogs.check_device_availability.title"),
								html: this.$t("dialogs.check_device_availability.content"),
								confirmButtonText: this.$t("dialogs.check_device_availability.ok"),
								showCloseButton: false,
								icon: "error",
								allowOutsideClick: false,
								backdrop: `rgba(150,0,0,0.4)`,
							}).then(() => {
								this.fetchData();
							});
						});
				},
			});
		},
		handleGetCommands(item) {
			let userData = TokenService.getUser();
			evoapi
				.get("/customers/" + item.customer_id + "/devices/" + item.device_id + "/onboardingCommands")
				.then((response) => {
					let commands = response.data.join("<br><br>");
					Swal.fire({
						title: this.$t("dialogs.onboarding_commands.title"),
						html: this.$t("dialogs.onboarding_commands.content", { commands }),
						confirmButtonText: this.$t("dialogs.onboarding_commands.ok"),
						showCloseButton: true,
						icon: "success",
						allowOutsideClick: false,
						backdrop: `rgba(0,0,123,0.4)`,
					}).then((result) => {
						if (result.isConfirmed) {
							if (window.clipboardData && window.clipboardData.setData) {
								// Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
								return window.clipboardData.setData("Text", commands);
							} else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
								var textarea = document.createElement("textarea");
								textarea.textContent = commands;
								textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
								document.body.appendChild(textarea);
								textarea.select();
								try {
									return document.execCommand("copy"); // Security exception may be thrown by some browsers.
								} catch (ex) {
								} finally {
									document.body.removeChild(textarea);
								}
							}
						}
					});
				})
				.catch(function (error) {
					if (error.response) {
						Swal.fire({
							icon: "error",
							title: this.$t("responses.error.title"),
							text: this.$t("responses.error.content"),
							footer: this.$t("responses.error.footer"),
						});
					}
				});
		},
		handleDelete(item) {
			Swal.fire({
				title: this.$t("dialogs.delete_device_confirmation.title"),
				html: this.$t("dialogs.delete_device_confirmation.content", {
					subject: item.name,
				}),
				icon: "warning",
				showCancelButton: true,
				customClass: {
					confirmButton: "md-button md-success",
					cancelButton: "md-button md-danger btn-fill",
				},
				cancelButtonText: this.$t("general.cancel"),
				confirmButtonText: this.$t("dialogs.delete_device_confirmation.ok"),
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					let closeInSeconds = 30;
					const displayText = this.$t("dialogs.delete_device_confirmation.waiting");
					Swal.fire({
						title: this.$t("dialogs.delete_device_confirmation_progress.title"),
						html: displayText.replace(/#1/, closeInSeconds),
						timer: (closeInSeconds + 5) * 1000,
						allowEscapeKey: false,
						allowOutsideClick: false,
						didOpen: () => {
							Swal.showLoading();
						},
					});
					this.timer = setInterval(function () {
						closeInSeconds--;
						if (closeInSeconds == 0) {
							clearInterval(this.timer);
						}
						const sat = document.getElementById("swal2-html-container");
						if (sat && sat.innerHTML) {
							sat.innerHTML = displayText.replace(/#1/, closeInSeconds);
						}
					}, 1000);

					let userData = TokenService.getUser();
					evoapi
						.delete("/customers/" + item.customer_id + "/devices/" + item.device_id + "?byPass=false")
						.then((response) => {
							clearInterval(this.timer);
							this.deleteRow(item);
							this.filters.queryNameID = ""; // Resetta il filtro di ricerca
							Swal.fire({
								title: this.$t("dialogs.delete_device_confirmation_finish.title"),
								text: this.$t("dialogs.delete_device_confirmation_finish.content", {
									subject: item.name,
								}),
								icon: "success",
								customClass: {
									confirmButton: "md-button md-success",
								},
								buttonsStyling: false,
							});
						})
						.catch((error) => {
							clearInterval(this.timer);
							if (error.response) {
								if (error.response.data.error == "Timeout error connecting to device") {
									Swal.fire({
										title: this.$t("dialogs.delete_device_sure_confirmation.title"),
										text: this.$t("dialogs.delete_device_sure_confirmation.content"),
										icon: "warning",
										showCancelButton: true,
										customClass: {
											confirmButton: "md-button md-success",
											cancelButton: "md-button md-danger btn-fill",
										},
										cancelButtonText: this.$t("general.cancel"),
										confirmButtonText: this.$t("general.ok"),
										buttonsStyling: false,
									}).then((result) => {
										if (result.value) {
											Swal.fire({
												title: this.$t("dialogs.delete_device_confirmation_progress.title"),
												html: this.$t("dialogs.delete_device_confirmation_progress.content"),
												allowEscapeKey: false,
												allowOutsideClick: false,
												didOpen: () => {
													Swal.showLoading();
												},
											});
											evoapi
												.delete(
													"/customers/" +
														item.customer_id +
														"/devices/" +
														item.device_id +
														"?byPass=true"
												)
												.then((response) => {
													Swal.fire({
														title: this.$t(
															"dialogs.delete_device_confirmation_finish.title"
														),
														text: this.$t(
															"dialogs.delete_device_confirmation_finish.content",
															{ subject: item.name }
														),
														icon: "success",
														customClass: {
															confirmButton: "md-button md-success",
														},
														buttonsStyling: false,
													});
													this.deleteRow(item);
													this.filters.queryNameID = ""; // Resetta il filtro di ricerca
												})
												.catch(function (error) {
													Swal.fire({
														icon: "error",
														title: this.$t("responses.error.title"),
														text: this.$t("responses.error.content"),
														footer: this.$t("responses.error.footer"),
													});
												});
										}
									});
								} else {
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										html:
											this.$t("responses.error.content") +
											"<br><br><code>" +
											error.response.data.error +
											"</code></small",
										footer: this.$t("responses.error.footer"),
									});
								}
							}
						});
				}
			});
		},
		deleteRow(item) {
			let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.device_id === item.device_id);
			if (indexToDelete >= 0) {
				this.tableData.splice(indexToDelete, 1);
			}
		},

		handleMonitoringRequest(item) {
			if (item.is_monitored == false) {
				Swal.fire({
					title: this.$t("dialogs.add_device_monitoring.title"),
					text: this.$t("dialogs.add_device_monitoring.content"),
					icon: "warning",
					showCancelButton: true,
					customClass: {
						confirmButton: "md-button md-success",
						cancelButton: "md-button md-danger btn-fill",
					},
					cancelButtonText: this.$t("general.cancel"),
					confirmButtonText: this.$t("dialogs.add_device_monitoring.ok"),
					buttonsStyling: false,
				}).then((result) => {
					if (result.isConfirmed) {
						Swal.fire({
							title: this.$t("dialogs.add_device_monitoring_progress.title"),
							html: this.$t("dialogs.add_device_monitoring_progress.content"),
							allowEscapeKey: false,
							allowOutsideClick: false,
							didOpen: () => {
								Swal.showLoading();
								let req = {
									device_id: item.device_id,
								};
								let userData = TokenService.getUser();
								evoapi
									.get(
										"/customers/" +
											item.customer_id +
											"/health/check-device-availability/" +
											item.device_id
									)
									.then((response) => {
										Swal.close(); // Chiudi la notifica di attesa
										if (response.status === 200) {
											evoapi
												.post("/customers/" + item.customer_id + "/monitoring", req)
												.then((response) => {
													Swal.close(); // Chiudi la notifica di attesa
													if (response.status === 200) {
														Swal.fire({
															title: this.$t(
																"dialogs.add_device_monitoring_finish.title"
															),
															buttonsStyling: false,
															customClass: {
																confirmButton: "md-button md-success",
															},
														});
														item.is_monitored = true;
													} else {
														throw new Error("Device not available");
													}
												})
												.catch(function (error) {
													if (error.response) {
														Swal.fire({
															icon: "error",
															title: this.$t("responses.error.title"),
															text: this.$t("responses.error.content"),
															footer: this.$t("responses.error.footer"),
														});
													}
												});
										} else {
											throw new Error("Device not available");
										}
									})
									.catch((error) => {
										Swal.fire({
											title: this.$t("dialogs.check_device_availability.title"),
											html: this.$t("dialogs.check_device_availability.content"),
											confirmButtonText: this.$t("dialogs.check_device_availability.ok"),
											showCloseButton: false,
											icon: "error",
											allowOutsideClick: false,
											backdrop: `rgba(150,0,0,0.4)`,
										}).then(() => {
											this.fetchData();
										});
									});
							},
						});
					}
				});
			} else {
				Swal.fire({
					title: this.$t("dialogs.remove_device_monitoring.title"),
					text: this.$t("dialogs.remove_device_monitoring.content"),
					icon: "warning",
					showCancelButton: true,
					customClass: {
						confirmButton: "md-button md-success",
						cancelButton: "md-button md-danger btn-fill",
					},
					cancelButtonText: this.$t("general.cancel"),
					confirmButtonText: this.$t("dialogs.remove_device_monitoring.ok"),
					buttonsStyling: false,
				}).then((result) => {
					if (result.isConfirmed) {
						Swal.fire({
							title: this.$t("dialogs.remove_device_monitoring_progress.title"),
							html: this.$t("dialogs.remove_device_monitoring_progress.content"),
							allowEscapeKey: false,
							allowOutsideClick: false,
							didOpen: () => {
								Swal.showLoading();
							},
						});
						let req = {
							device_id: item.device_id,
						};
						let userData = TokenService.getUser();
						evoapi
							.delete("/customers/" + item.customer_id + "/monitoring", {
								data: req,
							})
							.then((response) => {
								Swal.fire({
									title: this.$t("dialogs.remove_device_monitoring_finish.title"),
									buttonsStyling: false,
									customClass: {
										confirmButton: "md-button md-success",
									},
								});
								item.is_monitored = false;
							})
							.catch(function (error) {
								if (error.response) {
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										text: this.$t("responses.error.content"),
										footer: this.$t("responses.error.footer"),
									});
								}
							});
					}
				});
			}
		},
		handleBackupRequest(item) {
			if (item.on_backup == false) {
				Swal.fire({
					title: this.$t("dialogs.add_device_backup.title"),
					text: this.$t("dialogs.add_device_backup.content"),
					icon: "warning",
					showCancelButton: true,
					customClass: {
						confirmButton: "md-button md-success",
						cancelButton: "md-button md-danger btn-fill",
					},
					cancelButtonText: this.$t("general.cancel"),
					confirmButtonText: this.$t("dialogs.add_device_backup.ok"),
					buttonsStyling: false,
				}).then((result) => {
					if (result.isConfirmed) {
						Swal.fire({
							title: this.$t("dialogs.add_device_backup_progress.title"),
							html: this.$t("dialogs.add_device_backup_progress.content"),
							allowEscapeKey: false,
							allowOutsideClick: false,
							didOpen: () => {
								Swal.showLoading();
							},
						});
						let req = {
							device_id: item.device_id,
						};
						let userData = TokenService.getUser();
						evoapi
							.post("/customers/" + item.customer_id + "/backups", req)
							.then((response) => {
								Swal.fire({
									title: this.$t("dialogs.add_device_backup_finish.title"),
									buttonsStyling: false,
									customClass: {
										confirmButton: "md-button md-success",
									},
								});
								item.on_backup = true;
							})
							.catch(function (error) {
								if (error.response) {
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										text: this.$t("responses.error.content"),
										footer: this.$t("responses.error.footer"),
									});
								}
							});
					}
				});
			} else {
				Swal.fire({
					title: this.$t("dialogs.remove_device_backup.title"),
					text: this.$t("dialogs.remove_device_backup.content"),
					icon: "warning",
					showCancelButton: true,
					customClass: {
						confirmButton: "md-button md-success",
						cancelButton: "md-button md-danger btn-fill",
					},
					cancelButtonText: this.$t("general.cancel"),
					confirmButtonText: this.$t("dialogs.remove_device_backup.ok"),
					buttonsStyling: false,
				}).then((result) => {
					if (result.isConfirmed) {
						Swal.fire({
							title: this.$t("dialogs.remove_device_backup_progress.title"),
							html: this.$t("dialogs.remove_device_backup_progress.content"),
							allowEscapeKey: false,
							allowOutsideClick: false,
							didOpen: () => {
								Swal.showLoading();
							},
						});
						let req = {
							device_id: item.device_id,
						};
						let userData = TokenService.getUser();
						evoapi
							.delete("/customers/" + item.customer_id + "/backups", {
								data: req,
							})
							.then((response) => {
								Swal.fire({
									title: this.$t("dialogs.remove_device_backup_finish.title"),
									buttonsStyling: false,
									customClass: {
										confirmButton: "md-button md-success",
									},
								});
								item.on_backup = false;
							})
							.catch(function (error) {
								if (error.response) {
									Swal.fire({
										icon: "error",
										title: this.$t("responses.error.title"),
										text: this.$t("responses.error.content"),
										footer: this.$t("responses.error.footer"),
									});
								}
							});
					}
				});
			}
		},
		provisionSelectedDevices() {
			if (this.selectedDevices.length === 0) {
				Swal.fire({
					title: this.$t("dialogs.provision_device_no_selection.title"),
					html: this.$t("dialogs.provision_device_no_selection.content"),
					confirmButtonText: this.$t("dialogs.provision_device_no_selection.ok"),
					showCloseButton: false,
					icon: "error",
					allowOutsideClick: false,
					backdrop: `rgba(150,0,0,0.4)`,
				});
			} else {
				this.$router.push({
					name: "Provision devices",
					params: { devices: this.selectedDevices },
				});
			}
		},
	},
	mounted() {
		this.fetchData();
		let userData = TokenService.getUser();
		this.role = userData?.role || null;
		if (this.role == "super-admin") {
			evoapi.get("/customers/0").then((response) => {
				this.customers = response.data;
			});
		} else {
			evoapi.get("/auth/customers").then((response) => {
				this.customers = response.data;
			});
		}
	},
	watch: {
		filters: {
			handler: function () {
				if (this.isDataReady) {
					this.applyFilters();
				}
			},
			deep: true,
		},
		isDataReady: {
			immediate: true,
			handler(newValue) {
				if (newValue) {
					this.$nextTick(() => {
						this.applyFilters();
						this.shouldDisplayContent = true;
					});
				}
			},
		},
		switchAllDevices: {
			handler(newVal) {
				localStorage.setItem("switchAllDevices", newVal); // Salva lo stato in localStorage
				this.fetchData(); // Ricarica i dati quando cambia lo switch
			},
		},
	},
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
	border: 0;
	margin-left: 20px;
	margin-right: 20px;
}
.icon-online {
	color: #4caf50 !important;
}
.icon-warning {
	color: #ff9800 !important;
}
.icon-danger {
	color: #dc3545 !important;
}
.button-warning {
	background-color: #ff9800 !important;
}
</style>
<style lang="scss" scoped>
.text-right .md-table-cell-container {
	display: flex;
	justify-content: flex-end;
	overflow-x: hidden;
}

.paginated-table.md-table {
	padding: 20px 20px 20px 20px;
}

.md-table {
	overflow-x: hidden;
	text-align: center;
}

.md-table-head {
	text-align: center;
	font-size: 20px;
}

.md-table .md-table-head:last-child {
	text-align: center;
	overflow-x: hidden;
}
.bordered-cell {
	border-right: 1px solid #ccc;
	text-align: center;
}

.bordered-cell:first-child {
	border-left: 1px solid #ccc;
}

.centered-content {
	text-align: center;
}

.centered-header th {
	text-align: center !important;
}
.md-table-head-container {
	text-align: center;
}

// .paginated-table {
// 	text-align: center;
// }
// .md-ripple {
// 	text-align: center;
// }

.table-stats {
	display: flex;
	align-items: center;
	text-align: right;
	flex-flow: row wrap;

	.td-price .td-total {
		display: inline-flex;
		font-weight: 500;
		font-size: 1.0625rem;
		margin-right: 50px;
	}

	&.table-striped .td-price {
		border-bottom: 0;
	}

	.td-price {
		font-size: 26px;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

	.td-price,
	> div {
		flex: 0 0 100%;
		padding: 12px 8px;
	}
}

.copy-paste-box {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: small;
}

.copy-paste-password {
	padding: 1rem;
	background-color: black;
	color: white;
	font-family: Söhne Mono, Monaco, Andale Mono, Ubuntu Mono, monospace !important;
	font-size: large;
	text-align: center;
}

.md-button.spacing-both {
	margin-left: 5px;
	margin-right: 5px;
}

.clipboard-tooltip {
	position: absolute;
	background-color: #333;
	color: white;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 12px;
	/* ... altri stili per il tooltip ... */
}

code:hover {
	font-weight: bold;
	text-decoration: underline;
	font-size: 105%;
	color: white;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	background-color: #007bff; /* un leggero grigio come esempio, personalizza a tuo piacimento */
	background: linear-gradient(45deg, #006ba6, #d5419e);
}
code {
	border-radius: 4px;
	transition: all 0.3s; /* 0.3 secondi come esempio, personalizza a tuo piacimento */
	cursor: pointer;
}

@media only screen and (min-width: 1281px) {
	.hide-on-large {
		display: none !important;
	}
}
.admin-switch-container {
	text-align: right;
	padding: 10px;
}

.switch-wrapper {
	display: inline-block;
	padding: 12px 20px;
	background: linear-gradient(145deg, #006ca686, #d5419f66);
	border-radius: 10px;
	transition: all 0.3s ease;
}

.switch-wrapper:hover {
	transform: translateY(-2px);
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
	background: linear-gradient(145deg, #d5419f66, #006ca686);
}

/* Stile per il testo con contorno nero */
.switch-text {
	font-weight: 700;
	color: white !important;
	text-shadow: -1px -1px 0 #5b6770, 1px -1px 0 #5b6770, -1px 1px 0 #5b6770, 1px 1px 0 #5b6770;
}

/* Assicurati che anche il testo dello switch sia bianco con contorno */
.custom-switch {
	color: white !important;
	font-weight: 700;
	text-shadow: -1px -1px 0 #5b6770, 1px -1px 0 #5b6770, -1px 1px 0 #5b6770, 1px 1px 0 #5b6770;
}

/* Se necessario, potresti dover sovrascrivere alcuni stili di Material Design */
.custom-switch .md-switch-label {
	color: white !important;
	font-weight: 700;
	text-shadow: -1px -1px 0 #5b6770, 1px -1px 0 #5b6770, -1px 1px 0 #5b6770, 1px 1px 0 #5b6770;
}

.md-switch {
	font-weight: 500;
	text-shadow: -1px -1px 0 #5b6770, 1px -1px 0 #5b6770, -1px 1px 0 #5b6770, 1px 1px 0 #5b6770;
}
</style>
